.icon-btn {
  margin-right: 15px !important;
  font-weight: normal !important;
  font-size: 13px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper > * {
    display: inline-block;
  }

  .mat-icon {
    margin-right: 5px;
    height: 15px;
    width: 15px;
    font-size: 15px;
  }

  .btn-spinner {
    margin-right: 5px;
    circle {
      stroke: white;
    }
  }
}

.primary-btn {
  border-color: #461e7d;
  background-color: #461e7d;
  color: #ffffff;
  font-weight: normal;
  font-size: 13px;
}
.primary-btn[disabled],
.primary-btn:disabled {
  border-color: #bbbbbb !important;
  background-color: #aaaaaa !important;
}

.secondary-btn {
  color: #461e7d !important;
  border-color: rgba(70, 30, 125, 0.6) !important;
  background-color: #fff !important;
  font-weight: normal !important;
  font-size: 13px !important;
  &:hover {
    background-color: rgba(70, 30, 125, 0.2) !important;
  }
}

.wide-btn {
  padding: 0 30px !important;
}

.tooltip-btn-wrapper {
  display: inline-block;
}
.mat-mdc-tooltip {
  background: rgba(0, 0, 0, 0.7);
  font-size: 13px;
}

@media screen and (max-width: 768px) {
}
