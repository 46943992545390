@import 'app/styles/button';
@import 'app/styles/sticky-header';

ngx-global-contact-footer {
  display: block;
  padding: 40px 25px 25px;
}

h6.tooltip-channel-header {
  margin-top: 10px;
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 700;
}
.tooltip-node-title {
  .ellipsis {
    max-width: 250px;
  }
}
.tooltip-compare-icon {
  margin-right: 5px;
}
.multiline-tooltip {
  white-space: pre-line;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  border-left: 5px solid var(--primary-darker-color) !important;
  background-color: var(--primary-color) !important;
  color: var(--text-primary-color) !important;
  margin-left: 0;
}

.daterangepicker .ranges li {
  color: var(--primary-color) !important;
  margin: 0 !important;
}

.date-range-control .fa-calendar {
  color: var(--primary-color) !important;
}

td.active {
  background-color: var(--primary-color) !important;
}

td.in-range:not(.active) {
  background-color: var(--datepicker-range-color) !important;
}

button.primary-btn,
button {
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.expandable-bar {
  overflow-x: hidden !important;
  overflow-y: hidden;
}

.mat-mdc-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-mdc-text-field-wrapper {
  background: white;
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-slide-toggle-thumb {
  background-color: #fafafa !important;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff !important;
  border-color: transparent;
  color: #999 !important;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  font-size: 1rem !important;
  border-radius: 4px;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 1rem !important;
  width: auto !important;
  border-radius: 6px;
  border-color: #cccccc;
}

.drp-buttons .btn {
  border-radius: 6px;
}

header.sidenav-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

app-node-selector span .ng-star-inserted {
  align-items: center !important;
  display: flex !important;
  height: auto !important;
}

app-node-selector .k-treeview {
  height: auto !important;
  white-space: initial !important;
  line-height: 16px !important;
  word-break: break-word;
  line-break: normal;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.67) !important;
}

app-node-selector .k-in {
  padding: 5px 20px 5px 0 !important;
}

li[aria-selected='true'] > div > kendo-checkbox {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-treeview .k-checkbox-wrapper {
  margin-left: 8px !important;
}

.k-in.k-state-focused,
.k-in:hover {
  background-color: rgba(0, 0, 0, 0) !important;
}

.k-treeview .k-in.k-state-selected {
  color: rgba(0, 0, 0, 0.67) !important;
  opacity: 0.6;
}

.mat-expansion-indicator::after {
  color: var(--primary-color) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.channels-multi-selector > .mat-form-field-wrapper {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.channels-multi-selector .mat-form-field-infix {
  padding: 0.7em 0 !important;
}

.highcharts-menu-item:hover {
  background-color: var(--primary-color) !important;
}

.tree-control > mat-icon > svg > path {
  fill: var(--primary-color) !important;
}

.tree-node-margin {
  background-color: var(--primary-color) !important;
}

.tree-node:hover {
  background-color: var(--primary-lighter-color) !important;
}

.enel-search:focus {
  border-color: var(--primary-color) !important;
}

span[data-z-index] {
  position: relative !important;
}
.tooltip-full {
  position: relative;
  display: inline-block;
}

.tooltip-full {
  position: absolute;
  right: 0;
  bottom: 20px;
  background: var(--primary-lighter-color);
  color: var(--primary-color);
  visibility: hidden;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.1s;
}

.tooltip-legend:hover + .tooltip-full {
  visibility: visible;
  opacity: 1;
}

mat-expansion-panel .mat-mdc-form-field-subscript-wrapper {
  height: 5px;
}

cdk-virtual-scroll-viewport::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: var(--sidebar-bg-color); /* Color of the scrollbar track */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: var(--primary-lighter-color); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color); /* Color of the scrollbar thumb on hover */
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--primary-color) !important;
}

.mat-form-field-appearance-outline {
  --mdc-outlined-text-field-outline-color: #ccc;
  --mdc-outlined-text-field-hover-outline-color: var(--primary-color);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-color);
}

.behind.hovered {
  --mdc-outlined-text-field-outline-color: var(--primary-color) !important;
}
